import React from "react"

import Layout from "../hoc/Layout"

const events = () => {
  return (
    <>
      <Layout bodyClass="contact">
        <div className="contact-outer">
          <div className="contact-inner">
            <div className="container-contact">
              <h2>Moonwood Office</h2>
              <ul className="no-style-type contact-list-1">
                <li>
                  <strong>Zoë Voborilova</strong>
                </li>
                <li>
                  <strong>Managing Director</strong>
                </li>
                <li className="nobreak">Moonwood and Children’s Booksellers Worldwide</li>
              </ul>

              <ul className="no-style-type contact-list-2">
                <li>Krnovicka 375</li>
                <li>190 14 PRAHA – 9</li>
                <li>CZECH REPUBLIC</li>
                <li>Tel: (00420) 737 69 0000</li>
                <li>www.moonwood-books.com</li>
                <li>
                  <a href="mailto:info@moonwood-books.com?subject=">info@moonwood-books.com</a>
                </li>
              </ul>

              <ul className="no-style-type contact-list-3">
                <li>
                  <strong>Invoice details</strong>
                </li>
                <li>EduCreate s.r.o.</li>
                <li>Krnovicka 375</li>
                <li>190 14 PRAHA 9</li>
                <li>CZECH REPUBLIC</li>
              </ul>

              <ul className="no-style-type contact-list-4">
                <li>CZ03463516</li>
                <li>DE325764673</li>
                <li>PL5263266442</li>
                <li>SK4120182242</li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default events
